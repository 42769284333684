import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"

const get_PurchaseOrderList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.PURCHASE_ORDER.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_PurchaseOrderSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.BARANG.PURCHASE_ORDER.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PurchaseOrderDetail = (kode, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.PURCHASE_ORDER.GET, {kode: kode}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PriceGoodsSupplier = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.PURCHASE_ORDER.PRICE_VENDOR, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_PurchaseOrderList,
  post_PurchaseOrderSave,
  get_PurchaseOrderDetail,
  get_PriceGoodsSupplier
}