<template>
  <div>
    <div
      class="dropzone-file"
      ref="dropzoneFileA"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="dropFiles"
    >
      <div
        class="dropzone-empty"
        @drop.prevent="dropFiles"
        v-if="listFile.length <= 0 && !isDisabled"
      >
        <div>
          Drop file here or
          <label class="text-primary pointer" :for="id_file">browse</label>
        </div>
        <span class="fw-normal">Max. File Size: 10mb</span>
      </div>
      <div
        class="dropzone-empty"
        v-else-if="isDisabled && listFile.length <= 0"
      >
        <div>Can't upload file</div>
      </div>
      <div v-else class="dropzone-file-list">
        <div
          v-for="(value, key) in listFile"
          :key="key"
          class="dropzone-file-item"
          id="gallery"
        >
          <div class="dropzone-preview">
            <span
              class="remove-file"
              @click="removeFile(key, value.id)"
              v-if="!isDisabled"
            >
              <svg
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  cx="12.5"
                  cy="12.8585"
                  rx="12.5"
                  ry="12.8585"
                  fill="#FF5252"
                />
                <path
                  d="M15.6654 10.5V18.8333H9.33203V10.5H15.6654ZM14.4779 5.5H10.5195L9.72786 6.33333H6.95703V8H18.0404V6.33333H15.2695L14.4779 5.5ZM17.2487 8.83333H7.7487V18.8333C7.7487 19.75 8.4612 20.5 9.33203 20.5H15.6654C16.5362 20.5 17.2487 19.75 17.2487 18.8333V8.83333Z"
                  fill="white"
                />
              </svg>
            </span>
              <a :href="value.link_preview" :data-type="value.type=='file'?'iframe':'image'" :style="{
                padding: value.type=='file' ? '20px 0' : '0'
              }"
              ><img :src="value.preview" alt="preview"
            /></a>
          </div>
        </div>
        <div class="dropzone-addfile">
          <label v-if="listFile.length <= max && !isDisabled" :for="id_file">
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 0.5C6.1 0.5 0.5 6.1 0.5 13C0.5 19.9 6.1 25.5 13 25.5C19.9 25.5 25.5 19.9 25.5 13C25.5 6.1 19.9 0.5 13 0.5ZM19.25 14.25H14.25V19.25H11.75V14.25H6.75V11.75H11.75V6.75H14.25V11.75H19.25V14.25Z"
                fill="#68CAF8"
              />
            </svg>
          </label>
        </div>
      </div>
      <input
        type="file"
        name="fileBrow[]"
        multiple
        :id="id_file"
        accept="image/*,.pdf, .doc,.docx,.xlsx, .xls"
        ref="fileBrow"
        @change="setChangeFile"
        class="d-none"
      />
    </div>
  </div>
</template>

<script>
import { v4 as uuid4 } from "uuid";
import $ from "jquery";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
export default {
  name: "fileUpload",
  props: {
    file: Object,
    maxFile: Number,
    disabled: Boolean,
  },
  emits: ["update", "delete"],
  data() {
    return {
      id_file: uuid4(),
      listFile: this.$props.file,
      isDisabled: this.$props.disabled,
      max: this.$props.maxFile ? this.$props.maxFile : 10,
      fileExtension: ["image/jpeg", "image/jpg", "image/png"],
      fileExtensionDoc: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ],
    };
  },

  mounted() {
    this.setFancyBox();
  },

  methods: {
    dragOver: function () {
      if (this.listFile.length <= this.max) {
        $("div.dropzone-file").addClass("on-drag");
      }
    },
    dragLeave: function () {
      if (this.listFile.length <= this.max) {
        $("div.dropzone-file").addClass("on-drag");
      }
    },
    dropFiles: function (event) {
      event.preventDefault();
      $("div.dropzone-file").removeClass("on-drag");
      var file = event.dataTransfer.files;
      $.each(file, (i, e) => {
        var size = e.size / 1024;
        if (
          size <= 10240 &&
          this.listFile.length <= this.max &&
          this.fileExtension.includes(e.type)
        ) {
          this.listFile.push({
            id: "",
            file: e,
            preview: URL.createObjectURL(e),
            link_preview: URL.createObjectURL(e),
            type: "image",
          });
        } else if (
          size <= 10240 &&
          this.listFile.length <= this.max &&
          this.fileExtensionDoc.includes(e.type)
        ) {
          this.listFile.push({
            id: "",
            file: e,
            preview: "/img/icon_documents.png",
            link_preview: URL.createObjectURL(e),
            type: "file",
          });
        }
      });
      this.$emit("update", this.listFile);
      this.setFancyBox();
    },
    formatBytes: function (bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    setFancyBox: function () {
      setTimeout(() => {
        Fancybox.bind("#gallery a", {
          groupAll: true, // Group all items
          on: {
            ready: (fancybox) => {
              console.log(`fancybox #${fancybox.id} is ready!`);
            },
          },
        });
      }, 500);
    },
    setChangeFile: function () {
      for (const key in this.$refs.fileBrow.files) {
        if (Object.hasOwnProperty.call(this.$refs.fileBrow.files, key)) {
          const e = this.$refs.fileBrow.files[key];
          var size = e.size / 1024;
          if (
            size <= 10240 &&
            this.listFile.length <= this.max &&
            this.fileExtension.includes(e.type)
          ) {
            this.listFile.push({
              id: "",
              file: e,
              preview: URL.createObjectURL(e),
              link_preview: URL.createObjectURL(e),
              type: "image",
            });
          } else if (
            size <= 10240 &&
            this.listFile.length <= this.max &&
            this.fileExtensionDoc.includes(e.type)
          ) {
            this.listFile.push({
              id: "",
              file: e,
              preview: "/img/icon_documents.png",
              link_preview: "/img/icon_documents.png",
              type: "file",
            });
          }
        }
      }
      this.$emit("update", this.listFile);
      this.setFancyBox();
    },
    removeFile: function (key, id) {
      this.listFile.splice(key, 1);
      this.$emit("update", this.listFile);
      this.$emit("delete", id);
      this.setFancyBox();
    },
  },
  watch: {
    file: function (newVal) {
      this.listFile = newVal;
      this.setFancyBox();
    },
    disabled: function (newVal) {
      this.isDisabled = newVal;
    },
  },
};
</script>

<style>
.dropzone-file {
  border: 1px solid #e3e3e3;
  padding: 10px;
  border-radius: 5px;
  min-height: 140px;
  position: relative;
}
.dropzone-file .dropzone-empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dropzone-file .dropzone-empty {
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
}

.dropzone-file.on-drag {
  border-style: dashed;
  border-width: 3px;
  border-color: #68caf8;
}

.dropzone-file-list {
  display: flex;
  flex-wrap: wrap;
}
.dropzone-file-item {
  width: 20%;
  position: relative;
  padding: 5px;
}
.dropzone-file-item .remove-file {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 11px;
  cursor: pointer;
}
.dropzone-file-item .remove-file svg {
  width: 20px;
}
.dropzone-preview {
  height: 100%;
  border: 1px solid #eceff1;
  padding: 5px;
  position: relative;
}

.dropzone-preview a {
  display: flex;
  justify-content: center;
}

.dropzone-file-item img {
  width: 50%;
  height: auto;
}

.pointer {
  cursor: pointer;
}

.dropzone-addfile {
  display: flex;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}
.dropzone-addfile label svg {
  /* color: white;
  font-size: 11px;
  padding: 5px;
  border-right: 50%; */
  width: 20px;
}
</style>
