
import { API } from "../../constans/Url"
import { apiClient } from "../../helper"

const get_BarangList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_BarangSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.BARANG.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_BarangDetail = (kode, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.GET, {kode: kode}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_BarangPriceList = (kode, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.PRICE, {kode_barang: kode}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_BarangPengeluaranList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.OUT.LIST, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_BarangPengeluaranDetail = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.OUT.GET, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_BarangPengeluaranSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.BARANG.OUT.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_BarangPengeluaranSewaOut = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.BARANG.OUT.SEWA, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_BarangByGudang = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.BARANG.LIST_BARANG_GUDANG, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  post_BarangSave,
  get_BarangList,
  get_BarangDetail,
  get_BarangPriceList,
  post_BarangPengeluaranSave,
  get_BarangByGudang,
  post_BarangPengeluaranSewaOut,
  get_BarangPengeluaranList,
  get_BarangPengeluaranDetail
}