import { API } from "../../constans/Url";
import { apiClient } from "../../helper";


const get_ListVendor = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.VENDOR.LIST, params).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

const get_DetailVendor = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.VENDOR.GET, {id: id}).then(res => {
    if (res.status == 200){
      callbackSuccess(res.data)
    } else{ 
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_SaveVendor = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.VENDOR.SAVE, params).then(res => {
    if (res.status == 200 || res.status === 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

//KATEGORI

const get_ListKategoriVendor = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.VENDOR.KATEGORI.LIST, params).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_SaveKategoriVendor = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.VENDOR.KATEGORI.SAVE, params).then(res => {
    if (res.status == 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_CheckKategoriVendor = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.VENDOR.KATEGORI.CHECK, params).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

//CONTACT
const get_ListContactVendor = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.VENDOR.KONTAK.LIST, {id_vendor: id}).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_SaveContactVendor = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.VENDOR.KONTAK.SAVE, params).then(res => {
    if (res.status == 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_DetailContactVendor = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.VENDOR.KONTAK.GET, {id: id}).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_downloadFormatVendor = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.VENDOR.DOWNLOAD_FORMAT
    , params).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res)
    }
  }).catch(err =>{
    callbackError(err)
  })
}

export {
  get_CheckKategoriVendor, get_DetailContactVendor, get_DetailVendor, get_ListContactVendor, get_ListKategoriVendor, get_ListVendor, get_downloadFormatVendor, post_SaveContactVendor, post_SaveKategoriVendor, post_SaveVendor
};

